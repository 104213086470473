import * as React from "react"
import { Link } from "gatsby"
import logoImage from "../images/logo.png";

// markup
const NotFoundPage = () => {

  return (
    <main className="all_pages_main">
      <title>Page Not found</title>
      <div className="logo_main">
          <img src={logoImage} alt=""/>
      </div>
      <h2 className="page_title" >Page not found</h2>
      <p className="page_desc" >
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        
        <br />
        <Link to="https://hola.lulaschiller.com/">Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
